exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-[country]-[region]-[city]-[category]-[business]-index-js": () => import("./../../../src/pages/[country]/[region]/[city]/[category]/[business]/index.js" /* webpackChunkName: "component---src-pages-[country]-[region]-[city]-[category]-[business]-index-js" */),
  "component---src-pages-[country]-[region]-[city]-[category]-index-js": () => import("./../../../src/pages/[country]/[region]/[city]/[category]/index.js" /* webpackChunkName: "component---src-pages-[country]-[region]-[city]-[category]-index-js" */),
  "component---src-pages-[country]-[region]-[city]-[category]-index-old-js": () => import("./../../../src/pages/[country]/[region]/[city]/[category]/index_old.js" /* webpackChunkName: "component---src-pages-[country]-[region]-[city]-[category]-index-old-js" */),
  "component---src-pages-[country]-[region]-[city]-concrete-js": () => import("./../../../src/pages/[country]/[region]/[city]/concrete.js" /* webpackChunkName: "component---src-pages-[country]-[region]-[city]-concrete-js" */),
  "component---src-pages-[country]-[region]-[city]-index-js": () => import("./../../../src/pages/[country]/[region]/[city]/index.js" /* webpackChunkName: "component---src-pages-[country]-[region]-[city]-index-js" */),
  "component---src-pages-auto-mechanics-js": () => import("./../../../src/pages/auto-mechanics.js" /* webpackChunkName: "component---src-pages-auto-mechanics-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-service-[industry]-js": () => import("./../../../src/pages/service/[industry].js" /* webpackChunkName: "component---src-pages-service-[industry]-js" */),
  "component---src-pages-trident-electric-js": () => import("./../../../src/pages/trident-electric.js" /* webpackChunkName: "component---src-pages-trident-electric-js" */),
  "component---src-templates-city-js": () => import("./../../../src/templates/city.js" /* webpackChunkName: "component---src-templates-city-js" */),
  "component---src-templates-country-js": () => import("./../../../src/templates/country.js" /* webpackChunkName: "component---src-templates-country-js" */),
  "component---src-templates-region-js": () => import("./../../../src/templates/region.js" /* webpackChunkName: "component---src-templates-region-js" */)
}

